<script>
import User from './user.vue';
export default {
    // components: [User],
    name: "userOther",
    data() {
        return {
            value: ""
        };
    },
    components: { User }
}
</script>

<template>
  <User :id="$route.params.id"></User>
</template>